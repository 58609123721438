'use strict';

document.addEventListener('integration:feature-flag-provider', (event) => {
    event.detail.registrar.register({
        site: 'FM',
        'analytics.gtm.active': true,
        'voyado.tracker.active': true,
        'cart.active': true,
        'checkout.klarna.active': false,
        'checkout.klarna.instantshopping.active': false,
        // klarna + dark OR express + light
        'checkout.klarna.instantshopping.style.variation': '', // No Klarna integration on the site.
        'checkout.klarna.instantshopping.style.type': '', // No Klarna integration on the site.
        'customer.registration.enabled': false,
        'customer.registration.registrationTabFlag': false,
        'integration.gmf.active': true
    });
});
